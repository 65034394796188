import React, { useState, useEffect, useRef } from "react";
import { render } from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from "./redux/store";
import iconsearch from '../../../../../../prototype/src/assets/images/icon-search-2.svg';
import iconxb from '../../../../../../prototype/src/assets/images/icon-x-black.svg';
import iconx3 from '../../../../../../prototype/src/assets/images/icon-x-3.svg';
import useOutsideClick from "../../../../../scripts/util/useOutsideClick";
import { getAutoCompleteItemsData } from './redux/slices/autoCompleteSlice';
import { getSettings } from '../../../../../scripts/util/getSettings';

const Component = props => {

    const popularSearches = getSettings(['vmc', 'header', 'popularSearches']);
    const searchLandingPageUrl = getSettings(['vmc', 'header', 'searchLandingPageUrl']);

    const {
        mobile
    } = props;

    const autoCompleteItems = useSelector((state) => state.autoCompleteItems);
    const dispatch = useDispatch();

    const [searchVisible, setSearchVisible] = useState(false);
    const [closeVisible, setCloseVisible] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const searchFieldClick = () => {
        //setShowResults(true);
    };

    const handleClose = (() => {
        setShowResults(false);
        setSearchResults([]);
        setSearchTerm('');
    });

    const searchFieldChange = event => {
        setSearchTerm(event.target.value);
    };

    const searchOnPaste = e => {
        const textData = e.clipboardData.getData('Text');
        const filtered = textData;//.replace(/[^A-Za-z0-9 ]/, '');
        if (textData.length !== filtered.length) {
            e.preventDefault();
        }
    };

    const searchOnKeyDown = e => {
        let key = e.key;
        //key = key.replace(/[^A-Za-z0-9 ]/, '');
        if (key) {
            return;
        }
        e.preventDefault();
    };

    const searchOnKeyUp = e => {
        if (e.key === 'Enter' && searchTerm) {
            window.location = `${searchLandingPageUrl}?term=${encodeURIComponent(searchTerm)}`;
        } else if (e.key === 'Escape') {
            setSearchVisible(false);
        }
    };

    const previousController = useRef();
    useEffect(() => {
        if (previousController.current) {
            previousController.current.abort();
        }
        if (searchTerm) {
            const controller = new AbortController()
            previousController.current = controller;
            dispatch(getAutoCompleteItemsData(searchTerm, controller.signal));
        } else {
            setSearchResults([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (!autoCompleteItems.isFetching && autoCompleteItems.data.items) {
            setSearchResults(autoCompleteItems.data.items.map(item => item.query));
        }
    }, [autoCompleteItems]);

    // Focus the input box once the search box is main visible.
    useEffect(() => {
        if (searchVisible) {
            ref.current.querySelector('input.gs_field').focus();
        }
    }, [searchVisible]);

    const searchIconClick = () => {
        setSearchVisible(true);
        setCloseVisible(true);
    };

    const closeIconClick = () => {
        setSearchVisible(false);
        setSearchResults([]);
        setSearchTerm('');
    };

    const ref = useRef();
    useOutsideClick(ref, () => {
        if (searchVisible) {
            setSearchVisible(false);
            setShowResults(false);
            setSearchResults([]);
            setSearchTerm('');
        }
    });

    const searchIcon = () => {
        return (
            <button title="search" onClick={searchIconClick}>
                <svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.35.899a6.7 6.7 0 0 1 7.407 11.133l2.957 5.123-1.45.837-2.957-5.12A6.7 6.7 0 0 1 3.351.9zm.838 1.45a5.024 5.024 0 1 0 5.024 8.702A5.024 5.024 0 0 0 4.188 2.35z" fill="currentColor" fillRule="evenodd"></path>
                </svg>
            </button>

        );
    };

    const searchIconMobile = () => {
        return (
                    <button title="search" className="mobile_search" onClick={searchIconClick}>
                                       <svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M3.35.899a6.7 6.7 0 0 1 7.407 11.133l2.957 5.123-1.45.837-2.957-5.12A6.7 6.7 0 0 1 3.351.9zm.838 1.45a5.024 5.024 0 1 0 5.024 8.702A5.024 5.024 0 0 0 4.188 2.35z" fill="currentColor" fillRule="evenodd" />
                                       </svg>
                    </button>
        );
    };

    const searchIconClose = () => {
        return (
            <div className="mobile_search_close" onClick={closeIconClick}></div>
        );
    };

    const getSearchIcon = () => {
        return mobile ? searchIconMobile() : searchIcon();
    };

    const getSearchBox = (mobile) => {
        let positioning = 'desktop';
        if (mobile) {
            positioning = 'mobile';
        }
        return (
            <div>
            {closeVisible ? searchIconClose() : null}
            <div className={`global_search ${positioning}`} ref={ref}>
                <div className="gs_icon_close">
                    <img className="gs_close_icon" src={iconxb} onClick={closeIconClick} />
                </div>
                <div className="gs_input_field">
                    <input
                        className="gs_field"
                        type="text"
                        placeholder="Search Valley…"
                        value={searchTerm}
                        onChange={searchFieldChange}
                        onClick={searchFieldClick}
                        onPaste={searchOnPaste}
                        onKeyUp={searchOnKeyUp}
                        onKeyDown={searchOnKeyDown}
                        maxLength={100}
                    />
                    <img className="search_icon" src={iconsearch} alt="" />
                    <img onClick={handleClose} className="icon_close" src={iconx3} alt="" />
                </div>
                <div className="gs_content">
                    <div className="gs_content_list">
                        <h2>Popular Searches</h2>
                        <ul>
                            {popularSearches.map((item, i) => (
                                <li key={i}><a href={`${searchLandingPageUrl}?term=${encodeURIComponent(item.Term)}`}>{item.Name}</a></li>
                            ))}
                        </ul>
                    </div>

                    {showResults ?
                        <div className="gs_search_list">
                            <ul>
                                {searchResults.map((item, i) => (
                                    <li key={i}><a href={`${searchLandingPageUrl}?term=${encodeURIComponent(item)}`}>{item}</a></li>
                                ))}
                            </ul>
                        </div>
                        : null}

                </div>
                </div>
            </div>
        );
    };

    return searchVisible ? getSearchBox(mobile) : getSearchIcon();
};

render(
    <Provider store={store}>
        <Component />
    </Provider>,
    document.getElementById("globalSearchRoot")
);

render(
    <Provider store={store}>
        <Component mobile />
    </Provider>,
    document.getElementById("globalSearchRootMobile")
);

