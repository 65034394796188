import * as he from 'he';

const decodeObjEntities  = obj => {
  Object.keys(obj).forEach(key => {
    const item = obj[key];
    if (typeof item === 'string') {
      obj[key] = he.decode(item);
    }
  });
  return obj;
};

export { decodeObjEntities };