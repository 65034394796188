import { createSlice } from '@reduxjs/toolkit';

export const autoCompleteSlice = createSlice({
  name: 'autoComplete',
  initialState: {
    isFetching: false,
    data: {}
  },
  reducers: {
    getAutoCompleteItems: state => {
      state.isFetching = true;
    },
    getAutoCompleteItemsSuccess: (state, action) => {
      state.isFetching = false;
      state.data = action.payload;
    }
  }
});

export default autoCompleteSlice.reducer;

const {
  getAutoCompleteItems,
  getAutoCompleteItemsSuccess
} = autoCompleteSlice.actions;

const getAutoCompleteItemsData = (term, signal) => {
  return async (dispatch) => {
    dispatch(getAutoCompleteItems());
    return fetch(new Request(`/api/search/autocomplete/get?term=${term}`, {
      method: 'GET',
      signal: signal
    }))
    .then(response => response.json()).then(json => {
      dispatch(getAutoCompleteItemsSuccess(json));
    })
    .catch((err) => {
      console.log(err);
    });
  };
};

export { getAutoCompleteItemsData };