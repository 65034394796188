import { decodeObjEntities } from './decodeObjEntities';

const getSettings = (pathArr, useHe) => {
  if (pathArr && pathArr.length > 0) {
    let y = 0;
    for (let x = 1; x <= pathArr.length; x++) {
      let test = ['window'].concat(pathArr.slice(0, x)).join('.');
      const result = eval(test);
      if (result === undefined) {
        break;
      }
      y++;
    }
    if (pathArr.length === y) {
      const obj = eval(['window'].concat(pathArr.slice(0, y)).join('.'));
      if (useHe) {
        return decodeObjEntities(obj);
      }
      return obj;
    }
  }
  return undefined;
};

export { getSettings };
